import { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
import Icon from 'src/components/Icon';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// types
import type { ToolProps } from './Tool.interface';

// styles
import * as style from './Tool.module.scss';

const Tool: FC<ToolProps> = ({ icon, bwIcon, text }) => {
  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <div className={style.tool}>
      {icon && (
        <Icon className={classNames(style.icon, style.colorIcon)} name={icon} />
      )}
      {bwIcon && (
        <Icon className={classNames(style.icon, style.bwIcon)} name={bwIcon} />
      )}
      <div className={style.textWrapper}>
        <Typography
          className={classNames(style.text, style.animatedText)}
          variant='h5'
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default Tool;
