import { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import type { MobileInfoListProps } from './MobileInfoList.interface';
import * as style from './MobileInfoList.module.scss';

const MobileInfoList: FC<MobileInfoListProps> = ({ list, bgImage }) => {
  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <div className={style.list}>
      {list.map((item, i) => (
        <div
          className={style.image}
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
          key={i}
        >
          <Typography
            className={classNames(style.text, style.animatedText)}
            variant='h5'
          >
            {item}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default MobileInfoList;
