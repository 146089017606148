import { useContext, useEffect, useRef, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import { graphql, useStaticQuery } from 'gatsby';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Icon from 'src/components/Icon';
import MobileInfoList from 'src/components/MobileInfoList';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
import useMVPInfoData from 'src/cms/data/mvp/useMVPInfoData';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { drawSvg } from 'src/animations/svg';
// context
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';

import * as style from './HowItWorks.module.scss';

const query = graphql`
  {
    mobileImage: file(relativePath: { eq: "polygon.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const HowItWorks = () => {
  // hooks
  const { title, description, list, mobileProcessList } = useMVPInfoData();
  const { mobileImage } = useStaticQuery(query);
  const { isMobile } = useWindowSize();
  // refs
  const drawingImageRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  // context
  const { isPageLoaded } = useContext(SitePreloaderContext);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: false,
      swipeToSlide: true,
    }),
    []
  );

  useEffect(() => {
    const animationTitle = appearanceOfText(`.${style.title}`).pause();

    onHTMLElementEnterOnWindow(`.${style.title}`, () => {
      animationTitle.play();
    });
  }, []);

  useEffect(() => {
    if (drawingImageRef.current !== null && isPageLoaded) {
      const animation = drawSvg(drawingImageRef.current).pause();

      onHTMLElementEnterOnWindow(drawingImageRef.current, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  return (
    <Section title={title} className={style.root}>
      <div className={style.text}>
        <Typography variant='body' className={style.title}>
          {description}
        </Typography>
        <ul className={style.stepListWrapper}>
          {list.map((item, index) => (
            <li key={index} className={style.stepList}>
              <div className={style.dot} />
              <Typography className={style.stepListItem} variant='h5'>
                {item.content}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      {isMobile ? (
        <>
          <Typography className={style.mobileTitle} variant='h4'>
            Process
          </Typography>
          <Slider ref={sliderRef} {...sliderSettings}>
            {mobileProcessList.map((items, index) => (
              <MobileInfoList
                list={items}
                bgImage={mobileImage.childImageSharp.gatsbyImageData.src}
                key={index}
              />
            ))}
          </Slider>
        </>
      ) : (
        <div className={style.image} ref={drawingImageRef}>
          <Icon name='process' />
        </div>
      )}
    </Section>
  );
};

export default HowItWorks;
