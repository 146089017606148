// components
import Section from 'src/components/Section';
import DiscoveryPhaseList from 'src/components/DiscoveryPhaseList';
// hooks
import useDiscoveryPhaseData from 'src/cms/data/mvp/useDiscoveryPhaseData';

const DiscoveryPhase = () => {
  // hooks
  const { title, steps } = useDiscoveryPhaseData();

  return (
    <Section title={title}>
      <DiscoveryPhaseList steps={steps} />
    </Section>
  );
};

export default DiscoveryPhase;
