import React, { FC, useRef, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
// components
import Section from 'src/components/Section';
import Tool from 'src/components/Tool';
// services
import { sliceArray } from 'src/services/helpers';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
import useToolsData from 'src/cms/data/mvp/useToolsData';

import * as style from './Tools.module.scss';

const arraySize = 4;

const Tools: FC = () => {
  // hooks
  const { isMobile, isTablet } = useWindowSize();
  const data = useToolsData();
  // refs
  const sliderRef = useRef<Slider>(null);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: false,
      swipeToSlide: true,
    }),
    []
  );

  // todo complete styles or remove
  // useEffect(() => {
  //   const animation = appearanceOfText(`.${style.animatedText}`).pause();
  //
  //   onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
  //     animation.play();
  //   });
  // }, []);

  return (
    <Section title={data.title} className={style.root}>
      {!isMobile && !isTablet ? (
        <>
          <div className={style.list}>
            {data.toolList.map((tool, index) => (
              <Tool key={index} {...tool} />
            ))}
          </div>
        </>
      ) : (
        <>
          <Slider ref={sliderRef} {...sliderSettings}>
            {sliceArray(data.toolList, arraySize).map((tools, index) => (
              <div key={index} className={style.mobileList}>
                {tools.map((tool, i) => {
                  if (tool?.text.trim().length)
                    return <Tool key={i} {...tool} />;

                  return <React.Fragment key={i} />;
                })}
              </div>
            ))}
          </Slider>
        </>
      )}
    </Section>
  );
};

export default Tools;
